
<template>
  <div id="index">
    <div class="bg">
      <div class="top">
        <dv-decoration-8 style="width: 20%; height: 50px" />
        <dv-decoration-10 style="width: 60%; height: 32px" />
        <!-- <dv-decoration-10 style="width:90%;height:5px;" /> -->
        <dv-decoration-8 :reverse="true" style="width: 20%; height: 50px" />
      </div>
      <div class="title">
        <dv-decoration-6
          style="
            margin-right: 20px;
            width: 100px;
            height: 30px;
            padding-top: 11px;
          "
        />
        <span class="title-text">现场投票数据中心</span>
        <dv-decoration-6
          style="
            width: 100px;
            height: 30px;
            margin-left: 20px;
            padding-top: 11px;
          "
        />
      </div>
      <!-- <div class="search" :class="{ toHeight: isShow }" ref="navUl">
        <div>项目分组：</div>
        <div
          v-for="item in classify"
          class="compute"
          :class="{ active: active == item }"
          @click="selected(item)"
        >
          {{ item }}
        </div>
      </div> -->
      <div class="more" v-if="w>sw">
        <div class="morebt">
          <i
            v-if="!isShow"
            class="el-icon-arrow-down"
            style="font-size: xx-large"
            @click="morebt"
          ></i>
          <i
            v-else
            class="el-icon-arrow-up"
            style="font-size: xx-large"
            @click="morebt"
          ></i>
        </div>
      </div>
      <div class="introduce" ref="introduce">
        <div class="border"></div>
        <div style="padding-left: 10px;font-size: 35px;line-height: 1.75;">
          {{contestName}}
        </div>
      </div>

      <div class="chart">
        <div class="right">
          <div class="table-header">
            <div style="width:10vw;text-align: center">排名</div>
            <div style="width:35vw;text-align: center;">项目名称</div>
            <div style="width:15vw;text-align: center;">所属院校</div>
            <div style="width:15vw;text-align: center;">评审状态</div>
            <div style="flex-grow: 1;text-align: center">最终得分</div>
          </div>
          <div class="dv-scroll-board" style="width: 100%; height: 56.7vh; overflow: hidden;" ref="scrollBox">
            <div class="rows" ref="scrollBoxRows">
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import '../assets/iconfont.css'
var timer1; //这是实时获取数据的定时器
var timer2; //倒计时定时器
export default {
  data() {
    return {
      sw:0,         //search的宽度
      introduceH:'',  //inrroduxe的高度
      style1:'',
      style2:'',
      w:0,         //子元素的宽度和
      min: 0,      //倒计时分钟
      sec: 0,       //倒计时秒针
      time: "",    //用来显示倒计时的时间
      timer: "",   //定时器
      status:0,    //判断是否倒计时  1为开启

      classify: [],  //分组列表
      active: "",    //分组按钮是否被选中
      isShow: false,  //是否展示过多的分组


      //---请求所需数据
      contestId:'',  //赛事id
      contestName:'',  // 大赛名
      currentProjectname:'',  //当前在评项目的名字 
      contvotePartNumber:0,  // 当前再评项目的阶段 默认开始为0：转场等待
      currentProjectId:0, //当前在评项目id

      
      projectTime:'', //项目滚动定时器
      projectIndex:0, //项目下标
      projectCurrentList:'', //记录当前项目列表数据
      fullMarks:0, //总分
    };
  },
  mounted() {
    //获取组别信息，和大赛信息
    this.getGroupData();
  },
  methods: {
    timedown() {
      if (this.min <= 0 && this.sec <= 0) {
        return
      }
      if (this.sec > 0) {
        this.sec--;
      } else {
        this.min--;
        this.sec = 59;
      }
      this.time =
        (this.min >= 10 ? this.min : "0" + this.min) +
        ":" +
        (this.sec >= 10 ? this.sec : "0" + this.sec);
      this.countdown();
    },
    countdown(){
      let dospan = document.getElementById("countdown");
      if(dospan!=null){
        dospan.innerHTML= this.time;
      }
    },
    //分组按钮选中事件
    selected(name) {
      this.active = name; //高光
      this.loadProject();
    },
    //下拉菜单按钮切换
    morebt() {
      this.isShow = !this.isShow;
    },
    //弹出错误提示
    openError(text) {
        this.$message.error(text);
    },
    //----------向后台发请求
    //获取组别信息，和大赛信息
		getGroupData() {
        this.$post("/api/votelive/getVoteRateProjGroup", {
          contvoteId:this.$route.query.contvoteId,
        }).then((res) => {
          if(!res.result){ //失败  返回错误信息
            this.openError(res.resultMsg)
            clearInterval(timer1)
            return
          }
          else{
            this.classify=res.resultData.groupList;
            this.active = res.resultData.groupList[0];
            this.contestName=res.resultData.contest.contestName;
            this.contestId=res.resultData.contest.contestId;
            this.fullMarks=parseInt(res.resultData.fullMarks);
            this.loadProject();
          }
        }).catch((res) => {
          console.log(res);
        })
    },
    async loadProject(){
      var data = await this.getProject();
      if(!data.result){ //失败  返回错误信息
        this.openError(data.resultMsg)
      }else{
        //根据子元素的宽度判断分组按钮有没有换行
        // this.w=0
        // this.sw=this.$refs.navUl.clientWidth
        // this.$refs.navUl.children.forEach(v=>{
        //   this.w=this.w+v.clientWidth+parseInt(getComputedStyle(v).getPropertyValue('margin-right'))
        // })
        //判断class：introduce 的高度给表格进行不同的高度 
        // this.introduceH=this.$refs.introduce.clientHeight
        // if(this.introduceH>65){
        //   this.style1="width: 100%; height: 60%" 
        //   this.style2="width: 100%; height: 80%"
        // }else{
        //   this.style2="width: 100%; height: 87%"
        //   this.style1="width: 100%; height: 64%"
        // }
        this.currentProjectId = data.resultData.currentProj.currentProjectId;
        this.contvotePartNumber = data.resultData.currentProj.contvotePartNumber;
        this.changeprojectList(data.resultData.projectList);

        var partNumber = data.resultData.currentProj.contvotePartNumber;
        var status = data.resultData.currentProj.status;
        var startTime = data.resultData.currentProj.startTime;
        if(partNumber==1 || partNumber==2 || partNumber==3 || partNumber==4){
          //时间复位
          if(status==0 && startTime==''){
            this.setCurrentTime(data.resultData.currentProj);
            this.countdown();
            if(timer2){ 
              clearInterval(timer2); 
              timer2 = null;
            }
          }else if(status==1 && (this.min > 0 || this.sec > 0)){ //时间开始
            this.timedown();
            if(!timer2){
              timer2 = setInterval(function() {
                this.timedown();
              }, 1000);
            }
          }else{ //时间暂停
            this.setCurrentTime(data.resultData.currentProj);
            this.countdown();
            if(timer2){ 
              clearInterval(timer2); 
              timer2 = null;
            }
          }
        }else{
          this.setCurrentTime(data.resultData.currentProj);
          if(timer2){ 
            clearInterval(timer2); 
            timer2 = null;
          }
        }
        
        var that = this;
        //实时获取数据
        clearInterval(timer1);
        timer1 = setInterval(function() {
          that.nextTimeget()
        }, 1000);
      }
    },
    async nextTimeget(){
      let that = this;
      let data = await that.getProject();
      let projList = data.resultData.projectList;
      let projBoo = false;
      //判断项目列表数据有没有发生变化
      for(let i=0;i<projList.length;i++){
        let newProj = projList[i];
        let oldProj = that.projectCurrentList[i];
        if(newProj.contprojId != oldProj.contprojId || newProj.voteprojStatus != oldProj.voteprojStatus || newProj.voteprojScore != oldProj.voteprojScore){
          projBoo = true;
          break;
        }
      }
      //判断当前项目有没有发生变化
      let currentProjId = data.resultData.currentProj.currentProjectId;
      if(currentProjId != that.currentProjectId){
        projBoo=true;
        that.currentProjectId = currentProjId;
      }
      //当项目数据发生变化或当前项目发生变化，调整当前项目列表
      if(projBoo) that.changeprojectList(projList);

      let partNumber = data.resultData.currentProj.contvotePartNumber;
      that.contvotePartNumber = partNumber;
      let stspan = document.getElementById("stage-name");
      if(stspan!=null){
        if(partNumber==1){
          stspan.innerHTML="，路演："
        }else if(partNumber==2){
          stspan.innerHTML="，答辩："
        }else if(partNumber==3){
          stspan.innerHTML="，现场拉票："
        }else if(partNumber==4){
          stspan.innerHTML="，投票："
        }else{
          stspan.innerHTML=""
          let dospan = document.getElementById("countdown");
          dospan.innerHTML=""
        }
      }

      //倒计时
      let status = data.resultData.currentProj.status;
      let startTime = data.resultData.currentProj.startTime;
      if(partNumber==1 || partNumber==2 || partNumber==3 || partNumber==4){
        //时间复位
        if(status==0 && startTime==''){
          that.setCurrentTime(data.resultData.currentProj);
          that.countdown();
          if(timer2){ 
            clearInterval(timer2); 
            timer2 = null;
          }
        }else if(status==1 && (that.min > 0 || that.sec > 0)){ //时间开始
          if(!timer2){
            timer2 = setInterval(function() {
              that.timedown();
            }, 1000);
          }
        }else{ //时间暂停
          that.setCurrentTime(data.resultData.currentProj);
          that.countdown();
          if(timer2){ 
            clearInterval(timer2); 
            timer2 = null;
          }
        }
      }else{
        that.setCurrentTime(data.resultData.currentProj);
        if(timer2){ 
          clearInterval(timer2); 
          timer2 = null;
        }
      }
    },
    async getProject(){
      try {
        const res = await this.$post("/api/votelive/getVoteRateProj", {
          contvoteId: this.$route.query.contvoteId,
          groupName: null,
        });
        return res;
      } catch (error) {
        // 处理请求失败的情况
        console.log(error);
        return null;
      }
    },
    changeprojectList(projectList){
      this.projectCurrentList = projectList;

      const wrapper = this.$refs.scrollBoxRows;
      while (wrapper.firstChild) {
        wrapper.removeChild(wrapper.firstChild);
      }
      
      for(var i=0;i<projectList.length;i++){
        if(i<10){
          this.generateProjectHtml(projectList[i],i+1);
        }else{
          break;
        }
      }

      var that = this;
      let boo = false;
      if(this.projectTime) clearInterval(this.projectTime);
      if(projectList.length>9){
        that.projectIndex = 10;
        this.projectTime = setInterval(() => {
          let scrollBoxRows = that.$refs.scrollBoxRows;
          if(boo){
            scrollBoxRows.removeChild(scrollBoxRows.children[0]);
            that.generateProjectHtml(projectList[that.projectIndex], that.projectIndex+1);
            if(projectList.length>that.projectIndex+1){
              that.projectIndex++;
            }else{
              that.projectIndex=0;
            }
          }

          let children = scrollBoxRows.children[0];
          children.style.height = '0';
          boo = true;
        }, 2000);
      }
    },
    generateProjectHtml(project, inx){
      let scrollBoxRows = this.$refs.scrollBoxRows;
      const newChild = document.createElement('div');
      newChild.style="height: 6.3vh; line-height: 6.3vh; background-color: rgb(1, 35, 114);"
      newChild.classList.add("row-item");
      var str = '';
      var color = '';
      var score = '';
      var jbei = '';
      let jbeicolor=['gold','silver','darkgoldenrod'];
      var contvotePartName = '';
      if(this.contvotePartNumber==1){
        contvotePartName = '<span id="stage-name">，路演：</span><span id="countdown">'+this.time+'</span>';
      }else if(this.contvotePartNumber==2){
        contvotePartName = '<span id="stage-name">，答辩：</span><span id="countdown">'+this.time+'</span>';
      }else if(this.contvotePartNumber==3){
        contvotePartName = '<span id="stage-name">，现场拉票：</span><span id="countdown">'+this.time+'</span>';
      }else if(this.contvotePartNumber==4){
        contvotePartName = '<span id="stage-name">，投票：</span><span id="countdown">'+this.time+'</span>';
      }else{
        contvotePartName = '<span id="stage-name"></span><span id="countdown"></span>';
      }
      if(this.currentProjectId==project.contprojId){
        str = '在评'+contvotePartName; 
        color = 'red';
      }else if(project.voteprojStatus==1 && project.voteprojScore!=null ){ 
        str = '已评'; 
        color = 'yellow';
      }else{
        str = '未评'; 
        color = '';
      }
      var scoreSch = 0;
      if(project.voteprojStatus==0 || project.voteprojScore==null){
        score='--';
        jbei = '';
        scoreSch = 0;
      }else{
        score=project.voteprojScore;
        if(inx<=3){
          jbei = '<span class="iconfont icon-jiangbei" style="color:'+jbeicolor[inx-1]+'"></span>';
        }else{
          jbei = '';
        }
        color = 'yellow';
        scoreSch = score/this.fullMarks*100;
      } 

      newChild.innerHTML = '<div align="center" class="ceil" style="width: 10vw;">\
                              <div style="font-size:20px; font-family: Times New Roman, Times, serif">No.'+(inx)+jbei+'</div>\
                            </div>\
                            <div class="ceil" style="width: 35vw;">\
                              <div style="font-size:18px;margin: 0;padding: 0;text-align: center;text-overflow: ellipsis;overflow: hidden;">'+project.contprojName+'</div>\
                            </div>\
                            <div class="ceil" style="width: 15vw;">\
                              <div style="font-size:18px;margin: 0;padding: 0;text-align: center;text-overflow: ellipsis;overflow: hidden;">'+project.contprojOrgName+'</div>\
                            </div>\
                            <div class="ceil" style="width: 15vw;">\
                              <div style="color:'+color+';font-size: 20px;  text-align: center; font-weight: bold;">'+str+'</div>\
                            </div>\
                            <div class="ceil" style="flex-grow: 1;">\
                              <!--<div class="jdbar" style="position: relative; width:40%; top:3.7vh; height: 5px; background-color: #bbb;border-radius: 15px;">\
                                <div style="width: '+scoreSch+'%;height: 100%; background-color: gold;border-radius: 15px"></div>\
                              </div>-->\
                              <div style="font-size:22px;color:'+color+' ;text-align: center;">'+score+'</div>\
                            </div>';
      scrollBoxRows.appendChild(newChild);
    },
    setCurrentTime(data){
      this.min=parseInt(data.leftSec/60)
      this.sec=data.leftSec%60
      this.time =
      (this.min >= 10 ? this.min : "0" + this.min) +
      ":" +
      (this.sec >= 10 ? this.sec : "0" + this.sec);
    },
  },
  watch:{
  },
  beforeDestroy() {
    clearInterval(timer1);
    clearInterval(timer2);
    clearInterval(this.projectTime);
  }

};
</script>


<style scoped>
#index {
  color: #e5ebf8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;
}
.bg {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #00154f;
  background-image: url(../assets/bg\ \(1\).jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
}

.bg div,.bg span{
  font-size: 22px
}
.top {
  width: 100%;
  display: flex;
}
.title {
  width: 50%;
  position: absolute;
  top: 3.5%;
  left: 25%;
  display: flex;
  justify-content: center;
  font-size: xx-large;
}
.title .title-text {
  color: rgb(255, 255, 255);
  text-shadow: rgb(0, 216, 255) 0px 0px 25px;
  font-size: 46px;
  font-family: "Adobe 楷体 Std R";
}
.search {
  display: flex;
  margin: 5% 4% 0px;
  align-items: center;
  align-items: baseline;
  flex-wrap: wrap;
  height: 5%;
  overflow: hidden;
}
.search div{
  margin-right: 2%;
}
.toHeight{
    overflow: unset;
    height:auto; 
}
.compute {
  background-color: #072d84;
  cursor: pointer;
  padding: 0 10px;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  line-height: 30px;
  font-size: smaller;
  border: 1px solid floralwhite;
  margin-bottom: 1.5%;
}
.introduce {
  position: relative;
  display: flex;
  width: 92%;
  /* height: 65px; */
  background-color: #01205e;
  color: #01e1f2;
  margin: 5.1% 4% 2%;
  flex-wrap: wrap;
  /* overflow: hidden; */
  text-overflow: ellipsis
}
.border {
  position: absolute;
  height: 100%;
  background-color: #5cbee8;
  width: 5px;
  border-radius: 5px 0 0 5px;
  margin-right: 3px;
}
.chart {
  display: flex;
  /* margin-left: 26px; */
  height: 100%;
  margin-left: 4%;
  margin-right: 4%;
}
.right {
  width: 100%;
  height: 90%;
}
.right .table-header {
  display: flex;
  height: 5%;
  background-color: #072d84;
  border: 1px solid #5cbee8;
  align-items: center;
  /* justify-content: center; */
  border-radius: 5px 5px 0 0;
}

/*四个角的*/
.active {
  background-color: #3a8ee6;
}

.more {
  display: flex;
  flex-direction: column;
  margin: 0 68px;
}
.morebt {
  margin: 2px;
  text-align: center;
}
.morebt i {
  font-size: xx-large;
  width: 50px;
  border-radius: 15px;
}
.morebt i:hover {
  background-color: mediumblue;
  cursor: pointer;
}
</style>
